<template>

  <v-simple-table
      fixed-header
  >
    <template v-slot:default>
      <thead>
      <tr>
        <th class="audit-date text-subtitle-2">Timestamp</th>
        <th class="audit-user text-subtitle-2">Changed By</th>
        <th colspan="3" class="audit-field text-subtitle-2">
          <v-row>
            <v-col cols="4" sm="4">Field</v-col>
            <v-col cols="4" sm="4">Old Value</v-col>
            <v-col cols="4" sm="4">New Value</v-col>
          </v-row>
        </th>
        <th class="audit-comment text-subtitle-2">Comments</th>
      </tr>
      </thead>
      <tbody>

      <tr
          v-for="audit in auditLogs"
          :key="audit.id"
      >
        <td>{{ audit.created | formatDateTime }}</td>
        <td>
          <span v-if="audit.user">{{ audit.user.names }}</span>
        </td>
        <td colspan="3">

          <v-row
              v-for="field in audit.fields"
              :key="field.id"
              dense
          >
            <v-col
                cols="4"
                sm="4"
            >
              {{ field.name }}

            </v-col>
            <v-col
                cols="4"
                sm="4"
            >
              <span v-if="field.name === 'Joined Date'">{{ field.oldValue | formatDate }}</span>
              <span v-else-if="field.name === 'Payment Date'">{{ field.oldValue | formatDate }}</span>
              <span v-else-if="field.name === 'Payment Amount'">{{ field.oldValue | formatCurrencyUSD }}</span>
              <span v-else-if="field.name === 'Status'">{{ userStatusMap[field.oldValue] }}</span>

              <span v-else-if="field.name === 'Payment Status'">{{ paymentStatusMap[field.oldValue] }}</span>

              <span v-else>{{ field.oldValue }}</span>
            </v-col>
            <v-col
                cols="4"
                sm="4"
            >
              <span v-if="field.name === 'Joined Date'">{{ field.newValue | formatDate }}</span>
              <span v-else-if="field.name === 'Payment Date'">{{ field.newValue | formatDate }}</span>
              <span v-else-if="field.name === 'Payment Amount'">{{ field.newValue | formatCurrencyUSD }}</span>
              <span v-else-if="field.name === 'Status'">{{ userStatusMap[field.newValue] }}</span>

              <span v-else-if="field.name === 'Payment Status'">{{ paymentStatusMap[field.newValue] }}</span>

              <span v-else>{{ field.newValue }}</span>
            </v-col>

          </v-row>

        </td>
        <td>{{ audit.comment }}</td>

      </tr>

      </tbody>
    </template>
  </v-simple-table>

</template>

<script>

import api from '@/services/api'
import constants from '@/utils/constants'

export default {
  name: 'SharedAuditTrailView',

  created() {
    this.fetchAuditLogs()
  },

  props: [
    'itemId'
  ],

  methods: {
    fetchAuditLogs() {
      api
          .fetchAuditLogs(this.itemId)
          .then(response => {
            this.auditLogs = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      auditLogs: [],
      userStatusMap: constants.getUserStatusMap(),
      claimStatusMap: constants.getClaimStatusMap(),
      paymentStatusMap: constants.getPaymentStatusMap()
    }
  }
}

</script>

<style lang="sass">

th
  vertical-align: top

th.audit-date
  width: 15%
th.audit-user
  width: 10%

</style>
