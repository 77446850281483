<template>
  <v-main>

    <v-container
        id="claim-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-bank-transfer-in"
          class="px-5 py-3"
          ref="form"
      >

        <div>
          <v-alert
              type="error"
              outlined
              v-for="(error, i) in errors"
              :key="i"
          >
            {{ error }}
          </v-alert>
        </div>

        <v-form ref="claimForm"
                v-model="valid"
                @submit.prevent="validate"
                lazy-validation
        >

          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-select
                  v-model="computedUser"
                  label="Member"
                  :items="userList"
                  item-text="name"
                  item-value="id"
                  return-object
                  v-on:change="fetchDependents"
                  :rules="rules.required"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-select
                  v-model="computedDependent"
                  label="Dependent"
                  :items="dependentList"
                  item-text="name"
                  item-value="id"
                  return-object
                  :rules="rules.required"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="9"
            >
              <v-text-field
                  v-model="claim.cause"
                  label="Cause of Death"
                  :rules="rules.required"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-select
                  v-model="computedVerifier"
                  label="Verified By"
                  :items="verifierList"
                  item-text="name"
                  item-value="id"
                  return-object
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="9"
            >
              <v-textarea
                  v-model="comment"
                  label="Comment/Notes"
                  rows="1"
              ></v-textarea>
            </v-col>

          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="3"
            >
              <v-text-field
                  v-model="computedIncidentDate"
                  label="Incident Date"
                  type="date"
                  :rules="rules.required"
              ></v-text-field>
            </v-col>

          </v-row>


          <v-row>
            <v-col
                cols="12"
                sm="3"
            >
              <v-text-field
                  v-model="computedPaymentDate"
                  label="Payment Date"
                  type="date"
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                sm="3"
            >
              <v-text-field
                  v-model="claim.paymentAmount"
                  label="Payment Amount"
                  :rules="rules.optionalCurrency"
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                sm="3"
            >
              <v-text-field
                  v-model="claim.paymentMethod"
                  label="Payment Method"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="4"
            >
              <v-select
                  v-model="computedClaimStatus"
                  label="Status"
                  :items="claimStatusMap"
                  :rules="rules.required"
              ></v-select>
            </v-col>
          </v-row>


          <v-row>
            <v-col
                cols="12"
                sm="4"
            >
              <div class="my-2">
                <v-btn
                    color="primary"
                    dark
                    type="submit"
                >
                  Submit
                </v-btn>
              </div>
            </v-col>
          </v-row>

        </v-form>


      </base-material-card>


      <v-expansion-panels
          multiple
          light
          hover
          v-model="expandedPanels"
          v-if="claim.id"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Audit Trail</v-expansion-panel-header>

          <v-expansion-panel-content>
            <shared-audit-trail-view :item-id="claim.id"></shared-audit-trail-view>
          </v-expansion-panel-content>

        </v-expansion-panel>
      </v-expansion-panels>

    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import validation from "@/utils/validation";
import geoUtils from "@/utils/geography";
import moment from "moment";
import constants from "@/utils/constants";
import SharedAuditTrailView from "../shared/AuditTrailView";

export default {
  name: 'CoreClaimView',
  components: {SharedAuditTrailView},
  created() {
    this.claim.id = this.$route.params.claimId
    this.claim.user.id = this.$route.params.memberId
    if (this.claim.id !== undefined && this.claim.user.id !== undefined) {
      this.fetchClaim()
    }
    this.fetchUsers()
  },

  computed: {
    computedClaimStatus: {
      get() {
        return this.claimStatusMap[this.claim.status]
      },
      set(value) {
        this.claim.status = this.claimStatusMap.indexOf(value)
      }
    },
    computedDependent: {
      get() {
        if (this.claim.dependent === undefined) {
          return
        }
        return this.dependentList.filter(dep => {
          return this.claim.dependent.id === dep.id
        })[0]
      },
      set(value) {
        this.claim.dependent = value
      }
    },
    computedUser: {
      get() {
        if (this.claim.user === undefined) {
          return
        }
        return this.userList.filter(user => {
          return this.claim.user.id === user.id
        })[0]
      },
      set(value) {
        this.claim.user = value
      }
    },
    dependentList: {
      get() {
        let list = []
        this.dependents.forEach(dep => {
          if (this.claim.id === undefined && dep.status !== constants.DEPENDENT_STATUS_APPROVED) {
            return
          }
          let item = {
            id: dep.id,
            name: `${dep.names} (${dep.relationType.name})`
          }
          list.push(item)
        })
        return list
      }
    },
    computedVerifier: {
      get() {
        if (this.claim.verifiedBy == undefined) {
          return
        }
        return this.userList.filter(user => {
          return this.claim.verifiedBy.id === user.id
        })[0]
      },
      set(value) {
        this.claim.verifiedBy = value
      }
    },
    userList: {
      get() {
        let list = []
        this.users.forEach(user => {
          let item = {
            id: user.id,
            name: `${user.names}`
          }
          list.push(item)
        })
        return list
      }
    },
    verifierList: {
      get() {
        let list = []
        this.users.forEach(user => {
          if (user.id !== this.claim.user.id) {
            let item = {
              id: user.id,
              name: `${user.names}`
            }
            list.push(item)
          }
        })
        return list
      }
    },
    computedIncidentDate: {
      get() {
        return moment(this.claim.incidentDate).format('YYYY-MM-DD')
      },
      set(value) {
        this.claim.incidentDate = value
      }
    },
    computedPaymentDate: {
      get() {
        if (this.claim.paymentDate == null) {
          return
        }
        return moment(this.claim.paymentDate).format('YYYY-MM-DD')
      },
      set(value) {
        this.claim.paymentDate = value
      }
    }
  },

  methods: {
    validate() {
      if(this.$refs.claimForm.validate()) {
        if (this.claim.id !== undefined) {
          return this.updateClaim()
        }
        this.addClaim()
      }
    },
    fetchUsers() {
      api
          .fetchUsers()
          .then(response => {
            this.users = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchDependents() {
      api
          .fetchDependents(this.claim.user.id)
          .then(response => {
            this.dependents = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchClaim() {
      api
          .fetchClaim(this.claim.user.id, this.claim.id)
          .then(response => {
            this.claim = response.data
            this.fetchDependents()
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    addClaim() {
      let payload = this.buildPayload()

      api
          .addClaim(this.claim.user.id, payload)
          .then(response => {
            if (response.status === 201) {
              this.$router.push(`/claims`)
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    updateClaim() {
      let payload = this.buildPayload()
      api
          .updateClaim(this.claim.user.id, this.claim.id, payload)
          .then(response => {
            if (response.status === 200) {
              this.$router.push('/claims')
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },

    buildPayload() {
      let payload = {
        dependentId: this.claim.dependent.id,
        incidentDate: moment(this.claim.incidentDate).format('YYYY-MM-DD'),
        cause: this.claim.cause,
        notes: this.claim.notes,
        paymentAmount: this.claim.paymentAmount,
        paymentMethod: this.claim.paymentMethod,
        status: this.claim.status,
        comment: this.comment
      }

      if (this.claim.paymentDate) {
        payload.paymentDate = moment(this.claim.paymentDate).format('YYYY-MM-DD')
      }

      if (this.claim.verifiedBy != undefined) {
        payload.verifiedByUserId = this.claim.verifiedBy.id
      }

      return payload
    },

    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      valid: true,
      errors: [],
      userId: null,
      claimId: null,
      user: {},
      claim: {
        id: null,
        user: {}
      },
      dependents: [],
      users: [],
      comment: null,
      claimStatusMap: constants.getClaimStatusMap(),
      expandedPanels: [0],
      rules: {
        required: [
          v => validation.isRequired(v) || 'required'
        ],
        numeric: [
          v => validation.isInteger(v) || 'must be a number'
        ],
        optionalCurrency: [
          v => validation.isOptionalCurrency(v) || 'must be in currency format'
        ],
        birthYear: [
          v => validation.isInteger(v) || 'must be a year number',
          v => validation.isMoreThan(v, 1900) || 'must be after 1900',
          v => validation.isBeforeOrEqualToCurrentYear(v) || 'must be before or equal to current year'
        ]
      }
    }
  }
}
</script>